.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #b3bed3;
  /* background-image: url('https://tg.qpic.eu.org/file/9877ccb3b4e802ac2b1aa.jpg'); */
  /* background-image: url('https://tg.qpic.eu.org/file/ea280fb9843e66ce0e988.jpg'); */
  background-image: url('https://tg.qpic.eu.org/file/9877ccb3b4e802ac2b1aa.jpg');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
